import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

import { Logo } from './Logo';

export const Loader: FC<{ size?: number }> = ({ size = 64 }) => {
  return (
    <StyledLoader>
      <StyledLogo $size={size} />
    </StyledLoader>
  );
};

const animation = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
`;

const StyledLoader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLogo = styled(Logo)<{ $size: number }>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  flex-shrink: 0;

  animation: ${animation};
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;
