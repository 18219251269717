import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

export const Module: FC<PropsWithChildren<{ name: string} >> = ({ name, children, ...props }) => {
  return (
    <StyledWrapper {...props}>
      <StyledTitle>{name}</StyledTitle>
      <StyledContent>{children}</StyledContent>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  width: 240px;
  overflow-x: hidden;
  padding: 16px;
  background: linear-gradient(to right top, #1C70F2, #1AC9FB);
  flex-shrink: 0;
`;

const StyledTitle = styled.h2`
  margin: 0;
  font-size: 12px;
`;

const StyledContent = styled.div`
  position: relative;
`;
