import { ComponentProps, FC, Suspense, lazy } from 'react';

import { Loader } from '../modules/Loader';

export function createLoadable<ComponentType extends FC = FC, EName extends string = string>(
  importModulePromise: () => Promise<{
    [key in EName]: ComponentType;
  }>,
  name: EName,
  withLoader = true,
) {
  const LazyComponent = lazy<ComponentType>(() =>
    importModulePromise().then((module) => ({ default: module[name] })),
  );

  return (props: ComponentProps<typeof LazyComponent>) => {
    const component = <LazyComponent {...(props as any)} />;

    return withLoader ? <Suspense fallback={<Loader />} children={component} /> : component;
  };
}
