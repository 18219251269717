import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { PathFinderPage } from './modules/projects/pathFinder';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="projects">
          <Route path="path-finder" element={<PathFinderPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
